/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

//variables
$baseColor: #191919;
$fontColor: #fff;

body {
  background: $baseColor;
  text-align: center;
  height: 100%;
}


